import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  BlockContent,
  Content,
  TextContainer,
  ImageContainer,
  ImageSrc,
} from './StyledElements';
import TxtSliderBlock from '../TxtSliderBlock'

const SliderBlock = ({ color, colorTxt, padding, backgroundColor, background, list }) => {
  const [ placement, setPlacement] = useState(0);

  return (
    <BlockContent color={color} padding={padding}>
        <Content>
            <ImageContainer>
              <ImageSrc fit="clip" htmlAttributes={{alt: list[placement].url}} src={list[placement].url} />
            </ImageContainer>
            <TextContainer>
            {
                list.map((e, index) => (
                  <TxtSliderBlock 
                    key={index}
                    infos={e}
                    isSelected={index === placement}
                    onClick={() => setPlacement(index)}
                    color={colorTxt}
                    linksPassed={false}
                    backgroundColor={backgroundColor}
                    background={background}
                  />
                ))
            }
            </TextContainer>
        </Content>
    </BlockContent>
  )
}

SliderBlock.propTypes = {
  color: PropTypes.string, 
  colorTxt: PropTypes.string, 
  padding: PropTypes.string, 
  backgroundColor : PropTypes.string, 
  background: PropTypes.string
};

SliderBlock.defaultProps = {
};

export default SliderBlock;