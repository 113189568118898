import React from 'react'
import PropTypes from 'prop-types';
import Button from '../../UIComponents/Button'
import Tag from '../../UIComponents/Tag'
import {
  BlockContent,
  Content,
  TextContainer,
  Title,
  Bodytxt,
  ImgCont,
  BlockImg,
} from './StyledElements'
import { navigate } from 'gatsby';

const Block = ({ buttonLabel, blockHeight, title, textIn, srcImg, feature, url, tagLabel }) => {
  
  const handleClickButton = async (link, type) => {
    if (type)
      await window.analytics.track('book-demo', {
        type
      });
    if (link)
      navigate(link);
  };

  return (
    <BlockContent>
      <Content blockHeight={blockHeight} feature={feature}>
        <TextContainer>
          {
            tagLabel ? <Tag label={tagLabel} type="orange" bottom={13} /> : null
          }
          <Title>{title}</Title>
          <Bodytxt marginBottom={!!buttonLabel}>{textIn}</Bodytxt>
          {
            buttonLabel ? <Button label={buttonLabel} onClick={() => handleClickButton(url, 'ready')}/> : null
          }
        </TextContainer>
        <ImgCont>
          <BlockImg  disableSrcSet={true} htmlAttributes={{alt:"Organizational change"}} src={srcImg} height={454} width={638}/>
        </ImgCont>
      </Content>
    </BlockContent>
  )
}

Block.propTypes = {
  buttonLabel: PropTypes.string,
  blockHeight: PropTypes.number,
  title: PropTypes.string,
  textIn: PropTypes.string,
  srcImg: PropTypes.string,
  feature: PropTypes.bool,
  url: PropTypes.string
};

Block.defaultProps = {
};

export default Block;