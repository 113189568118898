import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Block,
  Content,
  TextSide,
  TitleContainer,
  Title
} from './StyledElements'
import Partners from '../../components/Partners';
import BuildBlock from '../../Blocks/BuildBlock'
import RoadBlock from '../../Blocks/RoadBlock'
import StepsBlock from '../../Blocks/StepsBlock';
import EcoBlock from '../../Blocks/EcoBlock';

const BlocksContainer = () => {
  const { t } = useTranslation();
  return (
    <Block>
      <Content>
        <TextSide>
          <TitleContainer>
            <Title>{t('Clients')}</Title>
            <Partners />
          </TitleContainer>
        </TextSide>
        <RoadBlock
          title={t("Workflow")}
          textIn={t("WorkflowTxt")}
        />
        <StepsBlock
          title={t("Steps")}
          textIn={t("StepsTxt")} 
        />
        <EcoBlock
          title={t("Ecosystem")}
          textIn={t("EcoTxt")}
        />
        <BuildBlock
          title={t("Workspace")}
          textIn={t("WorkspaceTxt")}
        />
      </Content>
    </Block>
  )
}

BlocksContainer.propTypes = {

};

BlocksContainer.defaultProps = {

};

export default BlocksContainer;