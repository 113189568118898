import React from 'react';
import PropTypes from 'prop-types';

export default function Letter({ width, height, color, title}) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <path d="M32.2399 21.6523C30.2778 22.209 27.6832 22.407 25.7499 22.44L16.6749 4.34312H16.1673L7.0938 22.44C5.15894 22.407 2.88055 22.209 0.919922 21.6523L12.4116 0.399994H20.6052L32.2399 21.6523Z" fill={color}/>
      <path d="M20.64 16.0587C20.64 18.3005 18.8007 20.12 16.5663 20.12C14.3287 20.12 12.52 18.2973 12.52 16.0587C12.52 13.8185 14.3336 12 16.5663 12C18.7959 12 20.64 13.8137 20.64 16.0587Z" fill={color}/>
    </svg>
  );
};

Letter.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
	
Letter.defaultProps = {
  height: '22',
  width: '31',
  color: '#ffffff',
  title: "Thales"
};