import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Block,
  Content,
  TextSide,
  TitleContainer,
  Title,
  Text,
} from './StyledElements'
import Button from '../../UIComponents/Button';
import { navigate } from 'gatsby';


const MultipleBlocksContainer = () => {
  const { t } = useTranslation();
  const handleClickButton = async (link, type) => {
    await window.analytics.track('book-demo', {
      type
    });
    if (link)
      navigate(link);
  };
  return (
    <Block>
      <Content>
      <TextSide>
        <TitleContainer>
          <Title>{t('Create')}</Title>
          <Text>{t('CreateTxt')}</Text>
          <Button label={t('Demo')} onClick={() => handleClickButton("https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1", 'apps')} />
        </TitleContainer>
      </TextSide>
      </Content>
    </Block>
  );
}

MultipleBlocksContainer.propTypes = {

};

MultipleBlocksContainer.defaultProps = {

};

export default MultipleBlocksContainer;