import * as React from "react";
import PropTypes from 'prop-types';

export default function Credit({ width, height, color, title}) {
  return (
	<svg height={height} width={width} viewBox="0 0 36 20" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
	  <g fill="none">
		  <path d="M35.387.38v3.097s-4.705 2.562-6.345 3.416c-.188.107-1.129.227-1.505-.467a9.496 9.496 0 0 1-.753-1.842c.565-.106 1.076-.213 1.358-.347 1.8-.974 7.245-3.857 7.245-3.857z" fill={color} fillRule="evenodd"/>
		  <path d="M23.988 2.342h-4.88s-3.991 7.408-6.72 12.426c1.963-.16 5.202-1.08 5.834-2.162.484-.828 3.132-6.54 3.132-6.54s1.062 2.59 2.244 5.446c1.25-.641 2.514-1.309 3.764-1.99l-3.374-7.18z" fill={color} fillRule="evenodd"/>
		  <path d="M26.529 18.599c.174.427.524.854 1.156.854h4.382l-3.24-6.82a130.24 130.24 0 0 1-3.898 2.109c.7 1.668 1.29 3.123 1.6 3.857z" fill={color} fillRule="evenodd"/>
		  <path d="M27.362 9.51a170.71 170.71 0 0 1-3.763 1.988c-5.458 2.79-10.794 4.965-15.109 4.965-1.8 0-5.336.04-5.336-2.389 0-3.617 8.603-9.21 12.796-8.555-.524-2.443-.914-3.404-2.62-3.404C10.022 2.128.6 6.36.6 12.66c0 3.217 2.446 7.101 8.535 7.101 4.759 0 9.96-2.015 15.794-5.032a130.46 130.46 0 0 0 3.898-2.109c2.11-1.174 4.288-2.442 6.573-3.764V5.038c-2.58 1.428-5.296 2.977-8.038 4.471z" fill={color} fillRule="evenodd"/>
	  </g>
    </svg>
  );
};

Credit.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
	
Credit.defaultProps = {
  height: '19',
  width: '34',
  color: '#ffffff',
  title: "Credit Agricole"
};