import React from 'react';
import PropTypes from 'prop-types';

export default function Sncf({ width, height, color, title }) {
  return (
    <svg height={height} width={width} viewBox="0 0 43 13" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
	    <g fill="none">
	      <path d="M24.991 8.957c-.593-.68-.829-1.558-.682-2.536.35-2.334 2.66-3.809 5.752-3.809.652 0 1.322.04 1.925.116l.611-1.76c-.745-.113-1.648-.161-2.533-.161-2.742 0-5.127.928-6.672 2.563-.048.05-.098.05-.128.05-.048 0-.08-.009-.067-.05L23.97.983h-2.263L19.23 8.621c-.788-2.254-2.41-5.218-4.394-7.638h-2.43l-1.818 5.56c.914.668 1.203 1.15 1.442 1.835.014.04.043.049.087.049.045 0 .066-.01.078-.05l1.585-4.831c1.6 2.249 3.187 5.208 4.038 8.332h2.607l1.29-3.959c.013-.04.041-.048.084-.048.046 0 .075.011.08.048.392 2.486 2.907 4.156 6.472 4.156 2.304 0 4.387-.594 4.906-.867l1.35-4.181h5.188l.528-1.62h-5.188l.895-2.745h6.182l.539-1.679h-8.684l-2.948 9.056c-.626.155-1.399.231-2.206.231-1.74 0-3.133-.406-3.922-1.313zm-14.64-6.302l.516-1.595A12.28 12.28 0 0 0 8.709.837c-2.846-.065-5.42.706-6.081 2.34-.784 1.939 1.44 3.029 3.368 3.926 1.53.713 2.74 1.392 2.314 2.28-.33.689-1.414 1.016-2.988.98-1.364-.031-3.065-.416-4.405-1.079L.12 10.978c.986.45 3.018 1.061 4.753 1.1 3.256.074 5.365-.732 6.21-2.474.999-2.063-1.48-3.192-3.47-4.136-1.558-.74-2.62-1.126-2.365-1.87.303-.88 1.87-1.215 3.29-1.183a8.436 8.436 0 0 1 1.813.24z" fill={color}/>
	    </g>
    </svg>
  );
};

Sncf.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
  
Sncf.defaultProps = {
  height: '11',
  width: '42',
  color: '#ffffff',
  title: "sncf"
};