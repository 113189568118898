import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  Title,
  Header,
  Icon,
  InfosTxt,
  ImageContainer,
  ImageSrc,
  RedirectInfos,
  LinkSimple,
  IconArrow
} from './StyledElements';
import ArrowDown from '../../assets/SVG/ArrowDown';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Arrow from '../../assets/SVG/Arrow';

const TxtSliderBlock = ({ infos, isSelected, onClick, color, backgroundColor, background}) => {
  const { t } = useTranslation();

  return (
    <Block isSelected={isSelected} onClick={onClick} backgroundColor={backgroundColor} background={background}>
      <AnimateHeight
        duration={ 500 }
        height={(isSelected) ? 'auto' : 0}>
        <ImageContainer>
          <ImageSrc fit="clip" htmlAttributes={{alt: infos.type}} src={infos.url}/>
        </ImageContainer>
      </AnimateHeight>
      <Header >
        <Title color={color}>{t(`sliderInfos.${infos.type}.title`)}</Title>
        <Icon className={isSelected ? 'isOpen' : 'closed'}>
          <ArrowDown color={color}/>
        </Icon>
      </Header>
      <AnimateHeight
        duration={ 500 }
        height={(isSelected) ? 'auto' : 0}>
        <InfosTxt color={color}>{t(`sliderInfos.${infos.type}.subtitle`)}</InfosTxt>
        {
          infos.urlLink ? 
            <RedirectInfos>
            <LinkSimple href={infos.urlLink}>{t(`sliderInfos.${infos.type}.linkInfo`)}</LinkSimple>
            <IconArrow>
              <Arrow width='14' height='11' />
            </IconArrow>
            </RedirectInfos>
          :
            null
        }
      </AnimateHeight>
    </Block>
  );
}

TxtSliderBlock.propTypes = {
  onClick: PropTypes.func,
  infos: PropTypes.object,
  isSelected: PropTypes.bool,
  color: PropTypes.string, 
  backgroundColor: PropTypes.string, 
  background: PropTypes.string
};

TxtSliderBlock.defaultProps = {
  onClick: () => {},
  infos: {},
  isSelected: false,
};

export default TxtSliderBlock;