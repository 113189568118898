import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  Title,
  BodyText,
  EvImg,
} from './StyledElements'

const StepsBlock = ({ title, textIn}) => {
  return (
    <Block>
      <EvImg fit="clip" htmlAttributes={{alt:"Transformation digitale"}} src="https://evolt-website.imgix.net/transfo/everyone4.png"  />
      <Title>{title}</Title>
      <BodyText>{textIn}</BodyText>
    </Block>
  )
}

StepsBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string
};

StepsBlock.defaultProps = {
};

export default StepsBlock;