import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  Title,
  BodyText,
  FacesImg,
  MelindaImg,
  RogerImg,
  Content
} from './StyledElements'

const BuildBlock = ({ title, textIn }) => {
  return (
    <Block>
      <Content>
        <MelindaImg
          htmlAttributes={{alt:"Organizational culture"}}
          src="https://evolt-website.imgix.net/feedback/melinda.png"
          fit="clip"
        />
        <FacesImg
          htmlAttributes={{alt:"Innovation management"}}
          src="https://evolt-website.imgix.net/feedback/faces4.png"
          fit="clip"
        />
        <Title>{title}</Title>
        <BodyText>{textIn}</BodyText>
        <RogerImg
          htmlAttributes={{alt:"New ways of working"}}
          src="https://evolt-website.imgix.net/transfo/roger.png"
          fit="clip"
        />
      </Content>
    </Block>
  )
}

BuildBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string,
};

BuildBlock.defaultProps = {
};

export default BuildBlock;