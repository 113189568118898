import colors from '../../styles/colors';

const tagTypes = {
  orange: {
    background: colors.buttons.orange,
    color: colors.texts.darkOrange,
    border: 'transparent'
  }
}

export default tagTypes;