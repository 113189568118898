import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  Block,
  Title,
  BodyText,
} from './StyledElements';
import Button from '../../UIComponents/Button';

const AppBlock = ({ title, textIn, buttonLabel, url }) => {
  const handleClickButton = async (link, type) => {
    if (type)
      await window.analytics.track('book-demo', {
        type
      });
    if (link)
      navigate(link);
  };

  return (
    <Block htmlAttributes={{alt:"Digital transformation"}} style={{backgroundImage: `url(https://evolt-website.imgix.net/transfo/custom.png)`}}>
      <Title>{title}</Title>
      <BodyText>{textIn}</BodyText>
      <Button label={buttonLabel} top={20} onClick={() => handleClickButton(url, 'custom-app')}/>
    </Block>
  )
}

AppBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string
};

AppBlock.defaultProps = {
};

export default AppBlock;
