import React from 'react'
import PropTypes from 'prop-types';
import tagTypes from './TagTypes'
import {
  TagContainer
} from './StyledElements';

const Tag = ({ type, label, bottom }) => {
  const typeTag = tagTypes[type] ?  tagTypes[type] : tagTypes.orange;
  return (
    <TagContainer {...typeTag} type={type} bottom={bottom}>
      {label}
    </TagContainer>
  );
}

Tag.propTypes = {
  bottom: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string
};

Tag.defaultProps = {
  type: 'orange',
  label: 'tag'
};

export default Tag;