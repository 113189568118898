import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';

export const Block = styled.div`
  background: ${colors.blocks.gradientPink};
  grid-column: span 5;
  border-radius: 18px;
  min-height: 420px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 834px) {
    grid-column: span 12;
    margin-top: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  padding: 40px;

  @media screen and (max-width: 834px) {
    padding : 50px;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h3`
  color: ${colors.titles.darkBlue};
  font-size: 26px;
`;

export const BodyText = styled.p`
  color: ${colors.texts.darkBlue};
  margin-top: 20px;
`;

export const LetterImg = styled(Imgix)`
  width: 100%;
  height: auto;
`;