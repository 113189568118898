import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  Container,
  Title,
  BodyText,
  LetterImg
} from './StyledElements'

const DeployBlock = ({ title, textIn}) => {
  return (
    <Block>
      <LetterImg  fit="clip" htmlAttributes={{alt:"Transformation"}} src={"https://evolt-website.imgix.net/transfo/video.png"} />
      <Container>
        <Title>{title}</Title>
        <BodyText>{textIn}</BodyText>
      </Container>
    </Block>
  )
}

DeployBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string
};

DeployBlock.defaultProps = {
};

export default DeployBlock;