import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';

export const Block = styled.div`
  width: 100%
  display: flex;
  flex-direction: column; 
  position: relative;  
  padding: 12px 0 20px 0;
  cursor: pointer;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    ${props => props.isSelected ?`
      height: 2px;
      background-color: ${props.backgroundColor};
    ` : `
      height: 1px;
      background-color: ${props.background};
    `}
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  .isOpen {
    transform: rotate(180deg);
  }
    
  .closed {
  }  
`;

export const Title = styled.h3`
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.color ? `${props.color}` : null};
`;

export const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .3s ease-in-out 75ms;
  color: ${props => props.color ? `${props.color}` : null};
`;

export const InfosTxt = styled.p`
  color: ${props => props.color ? `${props.color}` : `${colors.texts.white}`};
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  @media screen and (max-width: 800px) {
    margin-bottom: 22px;
  }
`;

export const RedirectInfos = styled.div`
  width: fit-content; 
  display: flex;
  flex-direction: row;
  margin-top: 13px;
`;

export const LinkSimple = styled.a`
  font-family: Euclid-Circular;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: ${colors.texts.blue};
  text-decoration: none;
`;

export const IconArrow = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: ${colors.texts.blue};
`;

export const ImageContainer = styled.div`
  display: none;
  width: 100%;
  margin-bottom: 22px;

  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const ImageSrc = styled(Imgix)`
  width: 100%;
  height: auto; 
  border-radius: 10px;
`;