import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';

export const Block = styled.div`
  grid-column: span 5;
  background: ${colors.blocks.blue};
  border-radius: 18px;
  min-height: 420px;
  padding: 0 40px 40px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    grid-column: span 12;
    padding: 0 20px 20px 20px;
    min-height: fit-content;
    margin-top: 20px;
  }
`;

export const Title = styled.h3`
  margin: 40px 0 20px 0;
  
`;

export const BodyText = styled.p`
  color: ${colors.texts.white};
`;

export const EvImg = styled(Imgix)`
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  width: 316px;
`;