import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from "react-imgix";

export const TitleContainer = styled.div`
  height: 100%;
  grid-column: span 12; 
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Title = styled.h1`
  max-width: 929px;
  @media screen and (max-width: 600px) {
    font-size: 42px;
    line-height: 42px;
  }
`;

export const BodyTxt = styled.p`
  color: ${colors.texts.darkBlue};
  font-size: 24px;
  line-height: 32px;
  max-width: 664px;
  margin-top: 24px;
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

export const Block = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 36px;
  box-sizing: border-box;
  background: ${colors.blocks.white}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1008px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  max-height: ${props => props.blockHeight ? props.blockHeight : '527px'}
`;
  

export const ImgCont = styled.div`
  width: 216px;
  height: fit-content;
  margin: auto 0;
  margin-bottom: 18px;
  @media screen and (max-width: 600px) {
    display:none !important;
  }
`;

export const BlockImg = styled(Imgix)`
  margin: 0 auto;
  margin-bottom: 18px;
  width: 216px;
  height: auto; 
  @media screen and (max-width: 600px) {
    display:none !important;
  }
`;
