import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Block,
  Content
} from './StyledElements';
import ProfileBlock from '../ProfileBlock';
import colors from '../../styles/colors';


const FeedbackBlock = ({ color }) => {
  const { t } = useTranslation();

  return (
    <Block color={color}>
      <Content>
        <ProfileBlock 
          name={t("Quote1.name")}
          position={t("Quote1.position")}
          txt={t("Quote1.txt")}
          srcImg="https://evolt-website.imgix.net/feedback/girl.png"
        />
        <ProfileBlock
          name={t("Quote2.name")}
          position={t("Quote2.position")}
          txt={t("Quote2.txt")}
          srcImg="https://evolt-website.imgix.net/feedback/boy.png"
        />
        <ProfileBlock
          name={t("Quote3.name")}
          position={t("Quote3.position")}
          txt={t("Quote3.txt")}
          srcImg="https://evolt-website.imgix.net/transfo/man.png"
        />
      </Content>
    </Block>
  )
}

FeedbackBlock.propTypes = {
  title: PropTypes.string
};

FeedbackBlock.defaultProps = {
  color: colors.blocks.gradientPink
};

export default FeedbackBlock;