import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import colors from '../../styles/colors'
import {
  Block,
  Content,
  Row,
  Title,
  LinkTxt,
  LinkInterTxt
} from './StyledElements';
import PropTypes from 'prop-types';
import LogoFooter from '../../assets/SVG/LogoFooter'

const Footer = ({ background, color, logoColor }) => {
  const { t } = useTranslation();
  
  return (
    <Block background={background} >
      <Content>
        <Row>
          <LogoFooter color={logoColor} />
          <LinkInterTxt color={color} to="/legal">{t("Terms")}</LinkInterTxt>
        </Row>
        <Row>
          <Title color={color}>{t("Tools")}</Title>
          <LinkInterTxt to="/" color={color}>{t("Feedback")}</LinkInterTxt>
          <LinkTxt color={color} href="evolt-feedback.evoltapp.com">{t("Feeback")}</LinkTxt>
          <LinkTxt color={color} href="https://evolt.io/platform-tour/user-persona/">{t("Persona")}</LinkTxt>
          <LinkTxt color={color} href="https://evolt.io/platform-tour/user-journey/">{t("Journey")}</LinkTxt>
          <LinkTxt color={color} href="https://evolt.io/platform-tour/brainstorming/">{t("Brainstorming")}</LinkTxt>
        </Row>
        <Row>
          <Title color={color}>{t("Company")}</Title>
          <LinkTxt color={color} href="https://evolt.io/about/">{t("About")}</LinkTxt>
          <LinkInterTxt color={color} to="/legal">{t("Legal")}</LinkInterTxt>
        </Row>
        <Row>
          <Title color={color}>{t("Follow")}</Title>
          <LinkTxt color={color} href="https://twitter.com/Evolt_io">{t("Twitter")}</LinkTxt>
          <LinkTxt color={color} href="https://dribbble.com/evolt">{t("Dribble")}</LinkTxt>
          <LinkTxt color={color} href="https://www.facebook.com/evoltdesignchangers/">{t("Facebook")}</LinkTxt>
          <LinkTxt color={color} href="https://www.linkedin.com/company/evolt/">{t("Linkedin")}</LinkTxt>
        </Row>
        <Row>
          <Title color={color}>{t("Start")}</Title>
          <LinkTxt color={color} href="https://platform.evoltapp.com">{t("Try")}</LinkTxt>
        </Row>
      </Content>
    </Block>
  )
}

Footer.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
};

Footer.defaultProps = {
  background: colors.blocks.darkBlue,
  color: colors.texts.white
};

export default Footer;