import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  TextSide,
  TitleContainer,
  Title,
  BodyTxt,
  ImageContainer,
  Img,
} from './StyledElements'
import { navigate } from 'gatsby';
import Button from '../../UIComponents/Button'

const ConceptBlock = ({ title, textIn, buttonLabel, url }) => {
  const handleClickButton = async (link, type) => {
    if (type)
      await window.analytics.track('book-demo', {
        type
      });
    if (link)
      navigate(link);
  };

  return (
      <Block>
        <TextSide>
          <TitleContainer>
            <Title>{title}</Title>
            <BodyTxt>{textIn}</BodyTxt>
            <Button label={buttonLabel} onClick={() => handleClickButton(url, 'export')}/>
          </TitleContainer>
        </TextSide>
        <ImageContainer>
          <Img
            disableSrcSet={true}
            src="https://evolt-website.imgix.net/transfo/delivrables.png"
            // imgixParams={{ crop: 'faces', ar: "59∶32", fit: "crop" }}
            fit="clip"
            htmlAttributes={{alt:"Digital platform"}}
          />
        </ImageContainer>
      </Block>
  )
}

ConceptBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string,
  buttonLabel: PropTypes.string,
  url: PropTypes.string
};

ConceptBlock.defaultProps = {
};


export default ConceptBlock;