import styled from 'styled-components'
import colors from '../../styles/colors'

export const Block = styled.div`
  background: ${colors.blocks.white};
  width: 100%;
  height: fit-content;
  padding: 0 37px 100px 37px;
  box-sizing: border-box;

  @media screen and (max-width: 400px) {
    padding: 64px 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1008px;
  margin: 0 auto;
`;