import styled from "styled-components";

export const TagContainer = styled.button`
  background: ${props => props.background};
  width: fit-content;
  margin-bottom: ${props => props.bottom ? `${props.bottom}px` : 0};
  border-radius: 100px;
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.border};
  cursor: pointer;
  padding: 0 12px;
  color: ${props => props.color};
  text-decoration: none;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  font-family: Inter-Regular;
`;