import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowDown({ width, height, color, title}) {
  return (
    <svg height={height} width={width} viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
	  <title>{title}</title>
	  <g fill="none">
		<path d="M9.778.378c.377-.377.88-.377 1.257 0 .377.377.377.88 0 1.257l-4.31 4.31a1.016 1.016 0 0 1-1.436 0l-4.31-4.31C.602 1.258.602.755.979.378c.377-.377.88-.377 1.257 0l3.77 3.772L9.779.378z" fill={color}/>
	  </g>
    </svg>
  );
};

ArrowDown.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
    
ArrowDown.defaultProps = {
  height: '12',
  width: '12',
  color: '#ffffff',
  title: "Outils de travail"
};