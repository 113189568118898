import React from 'react';
import PropTypes from 'prop-types';


export default function Arrow({ width, height, color, title}) {
  return (
	<svg height={height} width={width} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
	  <title>{title}</title>
	  <g fill="none">
		<path d="M15.138 5.529L10 .39l-.943.942 4 4H1.333a.666.666 0 1 0 0 1.334h11.724l-4 4 .943.942 5.138-5.138a.666.666 0 0 0 0-.942z" fill={color}/>
	  </g>
    </svg>
  );
};

Arrow.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
      
Arrow.defaultProps = {
  height: '12',
  width: '12',
  color: '#0555FF',
  title: "Nouvelles pratiques"
};