import styled from 'styled-components';
import colors from '../../styles/colors';
import Imgix from 'react-imgix';

export const Block = styled.div`
  grid-column: span 12;
  background-color: ${colors.blocks.greyBlue};
  border-radius: 18px;
  padding: 40px 0px 0 40px;
  min-height: 400px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  @media only screen and (max-width: 800px) {
    padding: 20px 0px 20px 20px;
    grid-template-columns: repeat(8, minmax(10px, 1fr));
  }
  position: relative;
  overflow: hidden;
`;


 export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 800px) {
   & button {
     display: none
   }
  }
`;

 export const Title = styled.h3`
  color: ${colors.titles.darkBlue};
  max-width: 325px;
  @media screen and (max-width: 600px) {
    max-width: 325px;
  }
`;

 export const BodyTxt = styled.p`
  color: ${colors.texts.darkBlue};
  margin : 12px 0 31px 0;
  max-width: 325px;
  @media screen and (max-width: 600px) {
    margin-bottom: 12px 0 5px 0;
  }
`;

export const ImageContainer = styled.div`
  grid-column: span 7;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  bottom: 0;
  @media screen and (max-width: 800px) {
    grid-column: span 8;
    grid-column-end: -1;
    justify-content: flex-end;
  }
`;

export const TextSide = styled.div`
  grid-column: span 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  @media screen and (max-width: 800px) {
    grid-column: span 8;
  }
`;

export const Img = styled(Imgix)`
  @media screen and (max-width: 800px) {
    max-width: 100%;
`;