import React from 'react';
import PropTypes from 'prop-types';

export default function Trefle({ width, height, color, title}) {
  return (
    <svg height={height} width={width} viewBox="0 0 28 32" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
	    <g fill="none">
		    <path d="M14.012.54c-.413.744-1.331 1.816-2.886 3.737-1.795 2.209-1.476 5.166-.619 6.91a30.67 30.67 0 0 1 1.98 5.274c.612 2.308.213 5.387.256 5.108h-1.565c0-3.761-1.178-7.297-4.05-8.868-4.07-2.233-8.085 1.638-6.806 5.658.669 2.115 1.867 2.822 2.208 2.822 0 0-.556-2.326 1.145-3.511 1.585-1.118 3.564-.443 4.475.895.869 1.262 1.008 3.003 1.008 3.003H6.235v2.139l5.873-.003s-.158 3.028-3.372 2.734c0 0 .393 2.377 3.372 1.136 0 0-.158 2.491 1.904 3.746 2.037-1.255 1.88-3.746 1.88-3.746 2.984 1.24 3.388-1.136 3.388-1.136-3.231.294-3.389-2.734-3.389-2.734l5.893.003v-2.14h-2.942s.152-1.741 1.007-3.002c.921-1.338 2.893-2.013 4.495-.895 1.685 1.183 1.153 3.51 1.153 3.51.326 0 1.518-.706 2.182-2.82 1.278-4.02-2.743-7.892-6.806-5.66-2.86 1.572-4.043 5.107-4.043 8.87h-1.547c.037.277-.377-2.803.25-5.11a29.184 29.184 0 0 1 1.97-5.273c.847-1.744 1.162-4.701-.627-6.91C15.34 2.356 14.405 1.285 14.012.54z" fill={color} fillRule="evenodd"/>
	    </g>
    </svg>
  );
};

Trefle.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
	
Trefle.defaultProps = {
  height: '30',
  width: '27',
  color: '#ffffff',
  title: "Revenu Quebec"
};