import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  Container,
  Title,
  BodyText,
  LetterImg
} from './StyledElements'

const EcoBlock = ({ title, textIn}) => {
  return (
    <Block>
      <LetterImg fit="clip" htmlAttributes={{alt:"Collaboration platform"}} src="https://evolt-website.imgix.net/transfo/ecosystem4.png"/>
      <Container>
        <Title>{title}</Title>
        <BodyText>{textIn}</BodyText>
      </Container>
    </Block>
  )
}

EcoBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string
};

EcoBlock.defaultProps = {
};

export default EcoBlock;