import styled from 'styled-components'
import {Link} from 'gatsby-plugin-react-i18next';

export const Block = styled.div`
  background: ${props => props.background};
  width: 100%;
  height: fit-content;
  padding: 20px 0 0 0;
  box-sizing: border-box;

  @media screen and (max-width: 834px) {
    padding: 20px 20px 64px 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1008px;
  margin: 0 auto;
  padding: 20px 0 100px 0;
  position: relative;
  justify-content: center;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background: rgba(224, 229, 233, 0.2);
    width: 100%;
  }

  @media screen and (max-width: 834px) {
    :before {
      width: calc(100% - 40px);
      left: 20px;
    }
  }
`;


export const Row = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & svg {
    margin-bottom: 17px;
  }

  @media screen and (max-width: 834px) {
    grid-column: span 6;
    margin-top: 20px;
  }
`;

export const TitleCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Title = styled.p`
  color: ${props => props.color};
  font-family: Inter-Regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (max-width: 834px) {
    margin-bottom: 0px;
  }
`;

export const LinkTxt = styled.a`
  text-align: left;
  text-decoration: none;
  font-family: Inter-Regular;
  font-size: 14px;
  line-height: 24px;
  /* text-transform: capitalize; */
  color: ${props => props.color};
  opacity: 0.8;
  width: 100%;
  @media screen and (max-width: 834px) {
   line-height: 48px;
  }
`;

export const LinkInterTxt = styled(Link)`
  text-align: left;
  text-decoration: none;
  font-family: Inter-Regular;
  font-size: 14px;
  line-height: 24px;
  /* text-transform: capitalize; */
  color: ${props => props.color};
  opacity: 0.8;
  width: 100%;
  @media screen and (max-width: 834px) {
   line-height: 48px;
  }
`;