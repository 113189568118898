import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Block,
  Content
} from './StyledElements'
import ConceptBlock from '../../Blocks/ConceptBlock';
import AppBlock from '../../Blocks/AppBlock';
import DeployBlock from '../../Blocks/DeployBlock';

const SecondBlocks = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <Content>
        <ConceptBlock
          title={t("Delivrable")}
          textIn={t("DelivrableTxt")}
          buttonLabel={t("Demo")}
          url="https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1"
        />
        <AppBlock
          title={t("Custom")}
          textIn={t("CustomTxt")}
          buttonLabel={t("Demo")}
          url="https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1"
        />
        <DeployBlock
          title={t("Platform")}
          textIn={t("PlatformTxt")}
        />
      </Content>
    </Block>
  );
}

SecondBlocks.propTypes = {

};

SecondBlocks.defaultProps = {

};

export default SecondBlocks;