import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';

export const Block = styled.div`
  grid-column: span 7;
  background: ${colors.blocks.darkBlue};
  border-radius: 18px;
  min-height: 420px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  box-sizing: border-box;

  @media screen and (max-width: 834px) {
    grid-column: span 12;
  }
`;

export const Content = styled.div`
  max-width: 400px;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;


export const Title = styled.h3`
  margin: 20px 0;
`;

export const BodyText = styled.p`
  color: ${colors.texts.white};
`;

export const FacesImg = styled(Imgix)`
  width: 142px;
  height: auto;
`;

export const MelindaImg = styled(Imgix)`
  align-self: flex-start;
  width: 104px;
  height: auto;
`;

export const RogerImg = styled(Imgix)`
  margin-top: 24px;
  align-self: flex-end;
  width: 88px;
  height: auto;
`;