import React from 'react';
import { 
  Container,
  Circle
} from './StyledElements';
import Credit from '../../assets/SVG/Credit';
import Trefle from '../../assets/SVG/Trefle';
import Accor from '../../assets/SVG/Accor';
import Sun from '../../assets/SVG/Sun';
import Sncf from '../../assets/SVG/Sncf';
import Letter from '../../assets/SVG/Letter';

const Partners = () => {
  return (
    <Container>
    <Circle>
      <Credit/>
    </Circle>
    <Circle>
      <Trefle/>
    </Circle>
    <Circle>
     <Letter/>
    </Circle>
    <Circle>
      <Accor />
    </Circle>
    <Circle>
      <Sncf />
    </Circle>
    <Circle>
      <Sun />
    </Circle>
    </Container>
  );
}

Partners.propTypes = {
};

Partners.defaultProps = {
};

export default Partners;