import React from 'react';
import PropTypes from 'prop-types';

export default function Sun({ width, height, color, title }) {
  return (
	<svg height={height} width={width} viewBox="0 0 39 38" xmlns="http://www.w3.org/2000/svg">
	  <title>{title}</title>
	  <g fill="none">
		<g clipPath="url(#clip0)">
		  <path d="M19.58 10.865a1.379 1.379 0 0 1-1.376-1.233L17.408.12h4.32l-.772 9.488c-.049.712-.652 1.257-1.376 1.257z" fill={color}/>
		  <path d="M15.477 11.933c-.628.355-1.424.166-1.81-.404L8.164 3.702l3.74-2.135 4.152 8.61c.29.64.048 1.4-.58 1.756z" fill={color}/>
		  <path d="M12.46 14.898a1.381 1.381 0 0 1-1.787.545l-8.76-4.08 2.171-3.676 7.965 5.408c.604.403.773 1.186.41 1.803z" fill={color}/>
		  <path d="M11.374 18.93c0 .712-.556 1.28-1.255 1.352l-9.68.783v-4.246l9.655.783c.725.047 1.28.616 1.28 1.328z" fill={color}/>
		  <path d="M12.46 22.962c.362.617.169 1.4-.41 1.78L4.083 30.15l-2.172-3.677 8.762-4.056c.651-.285 1.424-.048 1.786.545z" fill={color}/>
		  <path d="M15.477 25.927c.627.356.869 1.115.555 1.756l-4.152 8.61-3.74-2.135 5.502-7.827a1.416 1.416 0 0 1 1.835-.404z" fill={color}/>
		  <path d="M19.58 27.018c.724 0 1.303.546 1.376 1.234l.796 9.488h-4.344l.796-9.488a1.36 1.36 0 0 1 1.376-1.233z" fill={color}/>
		  <path d="M23.683 25.927c.628-.355 1.424-.166 1.81.404l5.503 7.827-3.74 2.135-4.128-8.61c-.29-.64-.048-1.4.555-1.756z" fill={color}/>
		  <path d="M26.7 22.962a1.381 1.381 0 0 1 1.786-.545l8.762 4.08-2.173 3.676-7.965-5.408a1.362 1.362 0 0 1-.41-1.803z" fill={color}/>
		  <path d="M27.81 18.93c0-.712.556-1.28 1.256-1.352l9.654-.783v4.246l-9.654-.783c-.724-.023-1.255-.616-1.255-1.328z" fill={color}/>
		  <path d="M26.7 14.898a1.327 1.327 0 0 1 .41-1.78l7.965-5.408 2.172 3.677-8.76 4.08a1.415 1.415 0 0 1-1.787-.57z" fill={color}/>
		  <path d="M23.683 11.933a1.333 1.333 0 0 1-.555-1.756l4.151-8.61 3.741 2.135-5.503 7.827a1.39 1.39 0 0 1-1.834.404z" fill={color}/>
		</g>
		<defs>
		  <clipPath id="clip0">
			<path d="M0 0h38.28v37.62H0z" fill={color} transform="translate(.44 .12)"/>
		  </clipPath>
		</defs>
	  </g>
    </svg>
  );
};

Sun.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
	
Sun.defaultProps = {
  height: '37',
  width: '38',
  color: '#ffffff',
  title: "Thelem"
};