import styled from 'styled-components'

export const Block = styled.div`
  width: 100%;
  min-height: 572px;
  background: ${props => props.color ? `${props.color}` : null};
  padding: 100px;
  box-sizing: border-box;
  @media only screen and (max-width: 800px) {
    padding: 100px 24px;
  }
`;

export const Content = styled.div`
  height: 100%;
  max-width: 1008px;
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: none;
  gap: 0 1rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    > blockquote:first-child {
      grid-column: span 12;
    }
    > blockquote:nth-child(2) {
      grid-column: span 12;
      margin-top: 40px;
    }
    > blockquote:last-child {
      grid-column: span 12;
      margin-top: 40px;
    }
  }

  @media only screen and (min-width: 800px) and (max-width: 1080px) {
    > blockquote:first-child {
      grid-column: span 6;
    }
    > blockquote:nth-child(2) {
      grid-column: span 6;
    }
    > blockquote:last-child {
      display: none;
    }
  }

`;
