import * as React from "react"
import Navbar from '../components/Navbar';
import BlocksContainer from '../Containers/BlocksContainer'
import RecBlock from '../Blocks/RecBlock'
import MultipleBlocksContainer from '../Containers/MultipleBlocksContainer'
import FeedbackBlock from '../Blocks/FeedbackBlock'
import Footer from "../components/Footer"
import Block from '../Blocks/Block'
import SliderBlock from '../Blocks/SliderBlock'
import '../styles/font.css';
import './layout.css';
import HeaderHtml from '../components/HeaderHtml';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import colors from "../styles/colors";
import SecondBlocks from "../Containers/SecondBlocks";

const darkSlider = [
  {
    type: "spread",
    url: "https://evolt-website.imgix.net/feedback/Methodologies_v2.jpg"
  },
  {
    type: "provide",
    url: "https://evolt-website.imgix.net/feedback/Projects_v2.jpg"
  },
  {
    type: "capitalise",
    url: "https://evolt-website.imgix.net/feedback/Ressources_v2.jpg"
  },
  {
    type: "access",
    url: "https://evolt-website.imgix.net/feedback/Experts_v2.jpg"
  }
];

const lightSlider = [
  {
    type: "persona",
    url: "https://evolt-website.imgix.net/feedback/Persona.jpg",
    urlLink: "https://evolt.io/platform-tour/user-persona/"
  },
  {
    type: "feedback",
    url: "https://evolt-website.imgix.net/feedback/Feedback.jpg",
    urlLink: "https://evolt-feedback.evoltapp.com/"
  },
  {
    type: "journey",
    url: "https://evolt-website.imgix.net/feedback/CustomerJourneyMap.jpg",
    urlLink: "https://evolt.io/platform-tour/customer-journey-map/"
  },
  {
    type: "business",
    url: "https://evolt-website.imgix.net/feedback/Brainstorming.jpg",
    urlLink: "https://evolt.io/platform-tour/business-model-canvas/"
  },
]

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderHtml page="index"/>
      <Navbar type="light" />
      <RecBlock
        title={t('Deploy')}
        textIn={t('DeployTxt')}
        buttonLabel={t('Demo')}
        isHere={true}
        srcImg="https://evolt-website.imgix.net/transfo/deploy-4.png"
        url="https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1"
        />
      <SliderBlock list={darkSlider} padding={'100px 20px'} color={colors.blocks.darkBlue} background={colors.borders.grey} />
      <BlocksContainer />
      <FeedbackBlock color={colors.blocks.lightBlue}/>
      <MultipleBlocksContainer />
      <SliderBlock list={lightSlider} padding={'100px 20px'} color={colors.blocks.white} colorTxt={colors.texts.black} sliderType={false} backgroundColor={colors.borders.secondBlue} background={colors.borders.darkerGrey}/>
      <SecondBlocks />
      <Block 
        title={t('Team')}
        textIn={t('Change')}
        buttonLabel={t('Demo')}
        url="https://calendly.com/bruno-goetzmann/evolt-you-meet-bruno-1"
        srcImg="https://evolt-website.imgix.net/transfo/GirlLaptop.png"
        titleHeight="38px"
        blockHeight={480}
      />
      <Footer logoColor={colors.texts.white}/>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;