import * as React from "react"
import PropTypes from 'prop-types'

export default function LogoFooter({ width, height, color, title }) {
  return (
    <svg height={height} width={width} viewBox="0 0 60 20" xmlns="http://www.w3.org/2000/svg">
	<title>{title}</title>
	<g fill="none">
		<path d="M7.19 6.896c2.904 0 5.342 1.929 5.342 5.498 0 .244 0 .444-.022.798H3.798c.11 1.818 1.574 3.015 3.414 3.015 1.552 0 2.594-.709 3.17-1.684l1.862 1.33c-.997 1.574-2.727 2.571-5.054 2.571-3.37 0-5.787-2.394-5.787-5.764 0-3.259 2.417-5.764 5.787-5.764zM3.91 11.42h6.162C9.806 9.823 8.52 8.959 7.1 8.959c-1.42 0-2.86.82-3.192 2.46z" fill={color}/>
		<path d="M13.264 7.118h2.704l3.37 7.76 3.392-7.76h2.727l-5.033 11.085h-2.15l-5.01-11.085z" fill={color}/>
		<path d="M31.886 18.424c-3.28 0-5.808-2.55-5.808-5.764 0-3.214 2.527-5.764 5.808-5.764 3.28 0 5.808 2.55 5.808 5.764 0 3.214-2.527 5.764-5.808 5.764zm0-2.261c1.95 0 3.325-1.53 3.325-3.503s-1.374-3.502-3.325-3.502c-1.973 0-3.348 1.53-3.348 3.502 0 1.973 1.375 3.503 3.348 3.503z" fill={color}/>
		<path d="M40.155 1.576h2.438v16.627h-2.438V1.576z" fill={color}/>
		<path d="M50.685 18.314c-2.128 0-3.79-1.175-3.79-3.68V9.29h-2.306V7.118h2.305V4.037h2.439v3.081h3.17v2.173h-3.17v4.944c0 1.374.598 1.884 1.862 1.884.466 0 .953-.089 1.308-.222v2.173c-.488.155-1.064.244-1.818.244z" fill={color}/>
		<path d="M56.113 18.376a1.93 1.93 0 1 0 0-3.86 1.93 1.93 0 0 0 0 3.86z" fill={color}/>
		<path d="M8.327 1.576A1.93 1.93 0 0 1 5.506 3.29l-.874 1.676c.545.284 1.15.432 1.765.431 2.11 0 4.058-1.71 4.058-3.82H8.327z" fill={color}/>
	</g>
</svg>
  );
}

LogoFooter.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};

LogoFooter.defaultProps = {
  height: '20',
  width: '60',
  color: "#26282D",
  title: "Nouvelles façons de travailler"
};