import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';
export const Block = styled.blockquote`
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const ImgFaces = styled(Imgix)`
  margin-bottom: 20px;
`;

export const Title = styled.cite`
  font-family: Gustavo-Regular;
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  text-align: center;
  color: ${colors.titles.black};
`;

export const PositionTitle = styled.p`
  font-family: Gustavo-Regular;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.texts.blue};
  margin-bottom: 12px;
  text-align: center;
`;

export const Presentation = styled.p`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${colors.texts.black};
`;