import React from 'react';
import PropTypes from 'prop-types';

export default function Accor({ width, height, color, title}) {
  return (
	<svg height={height} width={width} viewBox="0 0 27 31" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
	  <g fill="none">
		<path d="M26.92 29.592h-6.587l-4.841-10.417c-2.64 1.482-4.154 4.916-6.837 7.188-1.367 1.153-2.966 2.182-4.953 2.883-.903.31-2.433.719-3.01.781-.24.027-.412.018-.447-.08-.025-.07.043-.124.293-.248.292-.142 1.505-.666 2.261-1.207.946-.683 1.488-1.42 1.531-1.988-.258-.949-2.115-3.043-.215-6.903.68-1.384 1.273-2.378 1.694-3.38.482-1.154.826-2.769.946-3.976.009-.07.017-.07.06-.026.92.967 4.498 4.765 3.982 8.545 1.186-.47 3.25-1.908 4.205-2.671 1.006-.808 1.66-1.65 2.726-1.668.954-.018 1.031.452 1.797.559.19.026.464-.01.61-.089.06-.027.043-.098-.043-.115-1.031-.249-1.28-1.118-2.622-1.118-1.204 0-2.159 1.135-2.847 1.606l-3.38-7.276C9.809 6.957 10.239 4.748 13.343.4L26.92 29.592z" fill={color}/>
	  </g>
    </svg>
  );
};

Accor.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string
};
  
Accor.defaultProps = {
  height: '29',
  width: '26',
  color: '#ffffff',
  title: "Accor"
};