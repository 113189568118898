import React from 'react';
import PropTypes from 'prop-types';
import {
  Block,
  Title,
  BodyText
} from './StyledElements';

const RoadBlock = ({ title, textIn }) => {
  return (
    <Block htmlAttributes={{alt:"Project Management"}} style={{backgroundImage: `url(https://evolt-website.imgix.net/transfo/path4.png)`}}>
      <Title>{title}</Title>
      <BodyText>{textIn}</BodyText>
    </Block>
  )
}

RoadBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string
};

RoadBlock.defaultProps = {
};

export default RoadBlock;
