import styled from "styled-components";
import colors from '../../styles/colors'

export const Container = styled.div`
  display: flex; 
  width: 100%;
  height: fit-content;
  max-width: 518px;
  justify-content: space-between;
  flex-direction: row; 

  @media screen and (max-width: 600px) {
    max-width: 242px;
    height: 131px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
`;


export const Circle = styled.div`
  display: flex;
  background: ${colors.blocks.darkBlue};
  width: 58px;
  height: 58px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;