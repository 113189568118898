import styled from 'styled-components'
import colors from '../../styles/colors'

export const Block = styled.div`
  grid-column: span 7;
  background: ${colors.blocks.darkBlue};
  border-radius: 18px;
  min-height: 420px;
  padding: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;

  background-size: 375px 311px;
  background-repeat: no-repeat;
  background-position: top 0 right 0;
  
  @media screen and (max-width: 800px) {
    grid-column: span 12;
    padding: 20px;
    /* background-size: 90%; */
  }
`;


export const Title = styled.h3`
  max-width: 350px;
`;

export const BodyText = styled.p`
  color: ${colors.texts.white};
  margin: 20px 0 0 0;
  width: 70%;
`;
