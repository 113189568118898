import React from 'react'
import PropTypes from 'prop-types';
import {
  TitleContainer, 
  Title, 
  BodyTxt, 
  Content,
  BlockImg,
  Block
} from './StyledElements'
import Button from '../../UIComponents/Button';
import { navigate } from 'gatsby';

const RecBlock = ({ title, textIn, buttonLabel, srcImg, url }) => {
  
  const handleClickButton = async (link, type) => {
    if (type)
      await window.analytics.track('book-demo', {
        type
      });
    if (link)
      navigate(link);
  };

  // image fonctionne jsute pas de Imgix à placer

  return (
    <Block>
      <Content>
        <TitleContainer>
            <BlockImg fit="clip" htmlAttributes={{alt:"Project worflow"}} src={srcImg} />
          <Title>{title}</Title>
          <BodyTxt>{textIn}</BodyTxt>
          <Button label={buttonLabel} top={20} onClick={() => handleClickButton(url, 'main')} />
        </TitleContainer> 
      </Content>
    </Block>
  )
}

RecBlock.propTypes = {
  title: PropTypes.string,
  textIn: PropTypes.string,
  buttonLabel: PropTypes.string,
  srcImg: PropTypes.string,
  url: PropTypes.string
};

RecBlock.defaultProps = {
};

export default RecBlock;