import styled from 'styled-components'
import colors from '../../styles/colors'
import Imgix from 'react-imgix';

export const BlockContent = styled.div`
  width: 100%;
  height: fit-content;
  padding: 64px 36px;
  box-sizing: border-box;
  background: ${colors.blocks.darkBlue};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1008px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  min-height: ${props => props.blockHeight ? props.blockHeight : '527'}px;
`;

export const TextContainer = styled.div`
  grid-column: span 5;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: auto 0;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 834px) {
    grid-column: span 12;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h2`
`;

export const Bodytxt = styled.p`
  color: ${colors.texts.white};
  margin: 20px 0 0 0;
  ${props => props.marginBottom ? 'margin-bottom: 30px;' : null}
`;

export const ImgCont = styled.div`
  grid-column: span 7;
  grid-column-end: -1;
  margin: auto 0;
  @media screen and (max-width: 834px) {
    display: none !important;
  }
`;

export const BlockImg = styled(Imgix)`
  max-width: 100%;
  height: auto;
`;