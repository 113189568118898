import styled from 'styled-components';
import Imgix from 'react-imgix';

export const BlockContent = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 20px;
  box-sizing: border-box;
  background: ${props => props.color ? `${props.color}` : null};
  @media screen and (max-width: 800px) {
    padding: 64px 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(10px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
  max-width: 1008px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
`;

export const ImageContainer = styled.div`
  grid-column: span 7;
  height: 100%;
  @media screen and (max-width: 800px) {
    display: none !important;
  }

`;

export const ImageSrc = styled(Imgix)`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

export const TextContainer = styled.div`
  grid-column: span 5;
  grid-column-end: -1;
  display: flex;
  margin : 0 auto;
  position: relative;
  margin-bottom: 15px;
  height: fit-content;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    grid-column: span 12; 
    width: 100%;
  }
`;