import React from 'react'
import PropTypes from 'prop-types';
import {
  Block,
  ImgFaces,
  Title,
  PositionTitle,
  Presentation
} from './StyledElements'

const ProfileBlock = ({ name, position, txt, srcImg }) => {
  return (
    <Block>
      <ImgFaces width={80} height={80} htmlAttributes={{alt:"Innovation workflow"}} src={srcImg} />
      <Title>{name}</Title>
      <PositionTitle>{position}</PositionTitle>
      <Presentation>{txt}</Presentation>
    </Block>
  )
}

ProfileBlock.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  txt: PropTypes.string,
  srcImg: PropTypes.string
};

ProfileBlock.defaultProps = {
};

export default ProfileBlock;